<template>
  <ul :class="$style.root">
    <slot />
  </ul>
</template>

<style module lang="scss">
.root {
  composes: reset-list from global;
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--horizontal);

  @media (max-width: $breakpoint-s) {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
